import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChoreographyFileInfo, IInstructorChoreographyInfo } from './choreography.types';
import { lastValueFrom, tap } from 'rxjs';
import { ReleaseStoreService } from '../stores/release-store.service';
import { environment } from '../../../environments/environment';
import { ReleaseModel } from '../../models/release-model';
import { ProgramParserService } from '../programs/program-parser.service';

@Injectable()
export class ChoreographyService {
	constructor(
		private httpClient: HttpClient,
		private programParserService: ProgramParserService,
		private releaseStoreService: ReleaseStoreService,
	) {}

	public async getInstructorChoreography(): Promise<IInstructorChoreographyInfo[]> {
		return await lastValueFrom(
			this.httpClient.get<IInstructorChoreographyInfo[]>('/api/instructors/choreography/releases'),
		);
	}

	public async removeChoreography(instructorReleaseChoreographyId: string): Promise<void> {
		await lastValueFrom(
			this.httpClient.delete(`/api/instructors/choreography/releases/${instructorReleaseChoreographyId}`),
		);
	}

	public uploadChoreography(
		releaseId: string,
		file: File,
		progressCallback: (progress: number) => void,
	): Promise<unknown> {
		const formData = new FormData();
		formData.append('file', file);

		const req = new HttpRequest('POST', `/api/instructors/choreography/releases/${releaseId}`, formData, {
			reportProgress: true,
			responseType: 'json',
		});

		return lastValueFrom(
			this.httpClient.request(req).pipe(
				tap((event: HttpEvent<any>) => {
					switch (event.type) {
						case HttpEventType.UploadProgress:
							if (event.total) {
								const progress = Math.round((100 * event.loaded) / event.total);
								progressCallback(progress);
							}
							break;
						case HttpEventType.Response:
							return event.body;
					}
				}),
			),
		) as Promise<unknown>;
	}

	public async getReleaseFromFilename(filename: string): Promise<ChoreographyFileInfo | null> {
		const parsedInfo = this.parseFilename(filename);

		let program = this.programParserService.getProgramFromName(parsedInfo.programName);
		let release: ReleaseModel | null = null;

		if (program) {
			release = await this.getReleaseFromReleaseNumberText(program.getProgramId(), parsedInfo.releaseNumber);
			if (release) {
				return {
					program: program,
					release: release,
				};
			}
		}

		return null;
	}

	public getChoreographyUrl(instructorReleaseChoreographyId: string): string {
		return `${environment.API_URL}/api/instructors/choreography/releases/files/${instructorReleaseChoreographyId}`;
	}

	private async getReleaseFromReleaseNumberText(
		programId: string,
		releaseNumberText: string,
	): Promise<ReleaseModel | null> {
		const releaseNumber = Number(releaseNumberText);
		if (isNaN(releaseNumber)) {
			return null;
		}

		const releases = await this.releaseStoreService.getReleases();
		const release = releases.find((r) => r.getProgramId() === programId && r.getReleaseNumber() === releaseNumber);
		if (release) {
			return release;
		}

		return null;
	}

	private parseFilename(filename: string): { programName: string; releaseNumber: string } {
		// Define the possible program names
		const programNames = [
			'BODYPUMP',
			'BODYCOMBAT',
			'BODYATTACK',
			'BODYFLOW',
			'BODYBALANCE',
			// 'LES_MILLS_CORE',
			// 'CXWORX',
		];

		// Use a regular expression to capture the program name and release number
		const pattern = new RegExp(`(${programNames.join('|')})[ _]?(\\d+)(?=[^\\d]|$)`, 'i');

		// Execute the regex pattern on the filename
		const matches = filename.match(pattern);

		if (matches && matches.length >= 3 && filename.toLowerCase().includes('choreo')) {
			// The first captured group is the program name, the second is the release number
			return { programName: matches[1].toUpperCase(), releaseNumber: matches[2] };
		}

		// If no matches are found, return an empty object
		return { programName: '', releaseNumber: '' };
	}
}
