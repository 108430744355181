import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { ICreateExerciseRequest, IExerciseResponse, IUpdateExerciseRequest } from './models/exercise.types';
import { ExerciseModel } from '../../models/exercise.model';

@Injectable()
export class ExerciseService {
	constructor(private http: HttpClient) {}

	getExercises(): Observable<IExerciseResponse[]> {
		return this.http.get<IExerciseResponse[]>('/api/exercises');
	}

	async getAllExercises(): Promise<ExerciseModel[]> {
		const exercises = await lastValueFrom(this.http.get<IExerciseResponse[]>('/api/exercises'));
		return exercises.map((ex) => {
			return new ExerciseModel(ex.id, ex.exerciseName);
		});
	}

	createExercise(request: ICreateExerciseRequest): Observable<IExerciseResponse> {
		return this.http.post<IExerciseResponse>('/api/exercises', request);
	}

	updateExercise(request: IUpdateExerciseRequest): Observable<IExerciseResponse> {
		return this.http.put<IExerciseResponse>('/api/exercises', request);
	}

	async removeExericse(exerciseId: string): Promise<void> {
		return await lastValueFrom(this.http.delete<void>(`/api/exercises/${exerciseId}`));
	}
}
