import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProgramService } from '../programs/program.service';
import { ProgramModel } from '../../models/program.model';

@Injectable({
	providedIn: 'root',
})
export class ProgramStoreService {
	private readonly _programs: BehaviorSubject<ProgramModel[]>;
	private _isInitialized: BehaviorSubject<boolean>;

	readonly programs$: Observable<ProgramModel[]>;
	readonly isInitialized$: Observable<boolean>;

	constructor(private programService: ProgramService) {
		this._isInitialized = new BehaviorSubject<boolean>(false);
		this._programs = new BehaviorSubject<ProgramModel[]>([]);
		this.programs$ = this._programs.asObservable();
		this.isInitialized$ = this._isInitialized.asObservable();
	}

	get programs() {
		return this._programs.getValue();
	}

	set programs(val: ProgramModel[]) {
		this._programs.next(val);
	}

	public async initialize(): Promise<void> {
		if (this._isInitialized.getValue()) {
			return;
		}

		this.programs = await this.programService.getAllPrograms();
		this._isInitialized.next(true);
	}
}
