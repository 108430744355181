export class ExerciseModel {
	constructor(exerciseId: string, exerciseName: string) {
		this.exerciseId = exerciseId;
		this.exerciseName = exerciseName;
	}

	private exerciseId: string;
	private exerciseName: string;

	public getExerciseId(): string {
		return this.exerciseId;
	}

	public getExerciseName(): string {
		return this.exerciseName;
	}
}
