import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
	CreateReleaseTrackRequest,
	IReleaseTrackReadModel,
	UpdateReleaseTrackRequest,
} from './models/release-track.types';
import {
	IGfxExerciseSearchDocumentModel,
	IGfxMusicArtistSearchDocumentModel,
	IGfxReleaseTrackSearchDocumentReadModel,
	IGfxSongSearchDocumentModel,
} from '../search/models/search.types';
import { IInstructorReleasePlaylistTrackReadModel } from '../instructor-release-playlist/models/instructor-release-playlist.types';
import {
	ReleaseTrackSearchModel,
	ReleaseTrackSearchExerciseModel,
	ReleaseTrackSearchMusicArtistModel,
} from '../../models/release-track-search.model';
import { ReleaseTrackModel } from '../../models/release-track.model';

@Injectable()
export class ReleaseTrackService {
	constructor(private http: HttpClient) {}

	async createReleaseTrack(request: CreateReleaseTrackRequest): Promise<IReleaseTrackReadModel> {
		return lastValueFrom(this.http.post<IReleaseTrackReadModel>('/api/release-tracks', request));
	}

	async updateReleaseTrack(request: UpdateReleaseTrackRequest): Promise<IReleaseTrackReadModel> {
		return lastValueFrom(
			this.http.put<IReleaseTrackReadModel>(`/api/release-tracks/${request.releaseTrackId}`, request),
		);
	}

	async removeReleaseTrack(releaseTrackId: string): Promise<void> {
		return lastValueFrom(this.http.delete<void>(`/api/release-tracks/${releaseTrackId}`));
	}

	convertToReleaseTrackSearchModel(releaseTracks: ReleaseTrackModel[]): ReleaseTrackSearchModel[] {
		const searchModels = releaseTracks.map((releaseTrack) => {
			const exercises = releaseTrack.getExercises().map((exercise) => {
				return {
					exerciseId: exercise.getExerciseId(),
					exerciseName: exercise.getExerciseName(),
					releaseTrackId: exercise.getReleaseTrackId(),
				} as ReleaseTrackSearchExerciseModel;
			});

			const songs = releaseTrack.getSongs().map((song) => {
				const artists = song.getMusicArtists().map((artist) => {
					return {
						artistName: artist.getArtistName(),
						isFeaturedArtist: artist.getIsFeaturedArtist(),
						musicArtistId: artist.getMusicArtistId(),
						ordinal: artist.getOrdinal(),
					} as ReleaseTrackSearchMusicArtistModel;
				});

				return {
					releaseTrackId: song.getReleaseTrackId(),
					releaseTrackSongId: song.getReleaseTrackSongId(),
					songId: song.getSongId(),
					songName: song.getSongName(),
					trackLengthInSeconds: song.getTrackLengthInSeconds(),
					ordinal: song.getOrdinal(),
					musicArtists: artists,
				};
			});

			return {
				releaseTrackId: releaseTrack.getReleaseTrackId(),
				programId: releaseTrack.getProgramId(),
				programName: releaseTrack.getProgramName(),
				programFormatId: releaseTrack.getProgramFormatId(),
				programFormatName: releaseTrack.getProgramFormatName(),
				programFormatTrackId: releaseTrack.getProgramFormatTrackId(),
				releaseId: releaseTrack.getReleaseId(),
				releaseName: releaseTrack.getReleaseName(),
				trackFocus: releaseTrack.getTrackFocus(),
				trackNumber: releaseTrack.getTrackNumber(),
				trackDurationInSeconds: releaseTrack.getTrackLengthInSeconds(),
				isBonusTrack: releaseTrack.getIsBonusTrack(),
				averageRating: releaseTrack.getAverageRating(),
				numberOfRatings: releaseTrack.getNumberOfRatings(),
				songs: songs,
				exercises: exercises,
			} as ReleaseTrackSearchModel;
		});

		return searchModels;
	}

	convertToSearchDocumentReadModel(
		inputReleaseTrack: IInstructorReleasePlaylistTrackReadModel,
	): IGfxReleaseTrackSearchDocumentReadModel {
		const songs = inputReleaseTrack.songs.map((song) => {
			const artists = song.musicArtists.map((artist) => {
				return {
					musicArtistId: artist.musicArtistId,
					musicArtistName: artist.artistName,
					isFeaturedArtist: artist.isFeaturedArtist,
					ordinal: artist.ordinal,
				} as IGfxMusicArtistSearchDocumentModel;
			});

			return {
				songId: song.songId,
				songName: song.songName,
				songDurationInSeconds: song.trackLengthInSeconds,
				musicArtists: artists,
			} as IGfxSongSearchDocumentModel;
		});

		const exercises = inputReleaseTrack.exercises.map((exercise) => {
			return {
				exerciseId: exercise.exerciseId,
				exerciseName: exercise.exerciseName,
			} as IGfxExerciseSearchDocumentModel;
		});

		return {
			releaseTrackId: inputReleaseTrack.instructorReleasePlaylistTrackId,
			releaseNumber: inputReleaseTrack.releaseNumber,
			releaseName: inputReleaseTrack.releaseName,
			releaseId: inputReleaseTrack.releaseId,
			programId: inputReleaseTrack.programId,
			programName: inputReleaseTrack.programName,
			programFormatId: inputReleaseTrack.programFormatId,
			programFormatName: inputReleaseTrack.programFormatName,
			trackNumber: inputReleaseTrack.trackNumber,
			trackFocus: inputReleaseTrack.trackFocus,
			trackDesignation: inputReleaseTrack.trackDesignation,
			averageRating: inputReleaseTrack.averageRating,
			numberOfRatings: inputReleaseTrack.numberOfRatings,
			trackDurationInSeconds: inputReleaseTrack.songs.reduce((prev, next) => prev + next.trackLengthInSeconds, 0),
			isBonusTrack: inputReleaseTrack.isBonusTrack,
			isAlternateTrack: inputReleaseTrack.isAlternateTrack,
			songs: songs,
			exercises: exercises,
		} as IGfxReleaseTrackSearchDocumentReadModel;
	}
}
