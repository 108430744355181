import { Injectable } from '@angular/core';
import { ProgramStoreService } from '../stores/program-store.service';
import { ProgramModel } from '../../models/program.model';

@Injectable()
export class ProgramParserService {
	constructor(private programStoreService: ProgramStoreService) {}

	public getProgramFromName(programName: string): ProgramModel | undefined {
		const adjustedProgramName = programName.toLowerCase();
		const program = this.programStoreService.programs.find((p) =>
			p.getProgramName().toLowerCase().includes(adjustedProgramName),
		);

		if (program) {
			return program;
		}

		if (adjustedProgramName === 'bodyflow') {
			return this.programStoreService.programs.find((p) => p.getProgramName().toLowerCase().includes('bodybalance'));
		}
		if (adjustedProgramName === 'cxworx') {
			return this.programStoreService.programs.find((p) => p.getProgramName().toLowerCase().includes('core'));
		}

		return undefined;
	}
}
