import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReleaseService } from '../releases/release.service';
import { ReleaseModel } from '../../models/release-model';
import { ReleaseTrackModel } from '../../models/release-track.model';

@Injectable({
	providedIn: 'root',
})
export class ReleaseStoreService {
	private readonly _releases: BehaviorSubject<ReleaseModel[]>;
	private readonly _releaseTracks: BehaviorSubject<ReleaseTrackModel[]>;
	private _isInitialized: BehaviorSubject<boolean>;

	readonly releases$: Observable<ReleaseModel[]>;
	readonly releaseTracks$: Observable<ReleaseTrackModel[]>;
	readonly isInitialized$: Observable<boolean>;

	constructor(private releaseService: ReleaseService) {
		this._isInitialized = new BehaviorSubject<boolean>(false);
		this._releases = new BehaviorSubject<ReleaseModel[]>([]);
		this._releaseTracks = new BehaviorSubject<ReleaseTrackModel[]>([]);
		this.releases$ = this._releases.asObservable();
		this.releaseTracks$ = this._releaseTracks.asObservable();
		this.isInitialized$ = this._isInitialized.asObservable();
	}

	private get releases() {
		return this._releases.getValue();
	}

	private set releases(val: ReleaseModel[]) {
		this._releases.next(val);
	}

	private get releaseTracks() {
		return this._releaseTracks.getValue();
	}

	private set releaseTracks(val: ReleaseTrackModel[]) {
		this._releaseTracks.next(val);
	}

	public async initialize(): Promise<void> {
		if (this._isInitialized.getValue()) {
			return;
		}

		this.releases = await this.releaseService.getAllReleases();

		this.releaseTracks = this.releases.flatMap((r) => r.getReleaseTracks());

		this._isInitialized.next(true);
	}

	public async getReleases(): Promise<ReleaseModel[]> {
		if (!this._isInitialized.getValue()) {
			await this.initialize();
		}
		return this.releases;
	}

	public async getReleaseTracks(): Promise<ReleaseTrackModel[]> {
		if (!this._isInitialized.getValue()) {
			await this.initialize();
		}
		return this.releaseTracks;
	}
}
